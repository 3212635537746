import { inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';


import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PermissionService } from '../services/permission.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private permissionService: PermissionService,
        private router: Router,
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let token = localStorage.getItem(environment.tokenName);

        if (token) {
            //Check if token expired
            if(!this.authService.tokenExpired(token)){
                //check the permission/Role of the route
                if(this.permissionService.checkRoutePermissions(route) && this.permissionService.checkRouteRole(route)){
                    return true;
                }else{
                    //if does not have permssion for this page redirect to /
                    this.router.navigate(["/"]);
                    return true
                }
            }
        }
        // not logged in so redirect to login page with the return url
        this.authService.logout();
        return false;
    }
  
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      return true
    }
  
    canLogin(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let token = localStorage.getItem(environment.tokenName);
        //If user already logged in and token not expired, return false so that login route can not be accessed and navigate to "" so to dashboard
        if (token && !this.authService.tokenExpired(token)) {
            this.router.navigate([""]);
            return false;
        }

        return true;
    }
  }
  
  export const AuthGuardcanActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthGuard).canActivate(route, state)
  }
//   export const AuthGuardcanActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
//     return inject(AuthGuard).canActivateChild(route, state)
//   }
  
  export const AuthGuardcanLogin: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthGuard).canLogin(route, state)
  }
    
